<template>
  <v-container>
    <v-card class="px-4 py-2">
      <v-row>
        <v-col cols="12">
          <v-switch
            v-model="activateAutomation"
            :label="$t('generic.lang_activateAutomation')"
          />
        </v-col>

        <v-col cols="12">
          <v-checkbox
            :disabled="loading"
            v-model="sendAsEmail"
            :label="$t('generic.lang_sendAsEmail')"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            v-model="selectedCashierIDs"
            :items="cashiers"
            :label="$t('generic.lang_kasse')"
            item-text="name"
            item-value="id"
            multiple
            outlined
          />
        </v-col>

        <v-col cols="12" sm="6">
          <BaseTimeInput
            v-model="creationTime"
            :label="$t('generic.lang_timeOfCreation')"
            outlined
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            v-model="mode"
            :items="cashStockOptions"
            item-text="text"
            item-value="value"
            :label="$t('generic.lang_endCashMode')"
            outlined
          ></v-select>

          <div v-if="mode == 'b'">
            <v-currency-field
              v-model="cashStock"
              prepend-inner-icon="euro"
              :label="$t('erp.lang_cashEndTotal')"
              outlined
            ></v-currency-field>

            <CashoutNumpad
              @change="update"
              @del="del"
              :button-style="
                $vuetify.breakpoint.smAndDown
                  ? { height: 60, padding: 5 }
                  : { height: 75, padding: 7 }
              "
              double-zero
            />
          </div>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="loading"
          :loading="loading"
          @click="saveSettings"
        >
          {{ $t("generic.lang_save") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import CashoutNumpad from "@/components/pos/cashout/CashoutNumpad";
import BaseTimeInput from "@/components/common/BaseTimeInput.vue";

export default {
  name: "DailyReportAutomation",
  components: { CashoutNumpad, BaseTimeInput },
  mixins: [mixin],
  data() {
    return {
      loading: false,
      activateAutomation: false,
      selectedCashierIDs: [],
      cashiers: [],
      creationTime: "",
      sendAsEmail: false,
      mode: "a",
      cashStock: 0.0,
    };
  },
  computed: {
    cashStockOptions() {
      return [
        {
          text: this.$t("accounting.lang_setCashstockTo0"),
          value: "a",
        },
        {
          text: this.$t("accounting.lang_setCashstockToSpecificValue"),
          value: "b",
        },
        {
          text: this.$t("accounting.lang_setCashstockToActualCashstock"),
          value: "c",
        },
      ];
    },
  },

  mounted() {
    this.loadSettings();
    this.loadCashiers();
  },

  methods: {
    update(value) {
      this.cashStock = Number(this.cashStock) * 10;
      this.cashStock += Number(value) / 100;
    },
    del() {
      this.cashStock = 0.0;
    },
    loadCashiers() {
      this.axios.post(ENDPOINTS.GENERIC.CASHIERIDS.GET).then((res) => {
        this.cashiers = res.data.cashierIDs.map((elt) => {
          return {
            id: elt[0],
            name: this.$t("generic.lang_kasse") + " " + elt[0] + " " + elt[1],
          };
        });
      });
    },
    loadSettings() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.ACCOUNTING.DAILYREPORT.AUTOMATION.GET)
        .then((res) => {
          if (res.data.success) {
            this.activateAutomation = res.data.settings.activateAutomation;
            this.sendAsEmail = res.data.settings.sendAsEmail;
            this.selectedCashierIDs = res.data.settings.selectedCashierIDs;
            this.creationTime = res.data.settings.creationTime;
            this.mode = res.data.settings.endCash ? res.data.settings.endCash.mode : "a";
            this.cashStock = res.data.settings.endCash && res.data.settings.endCash.value ? res.data.settings.endCash.value : 0.0;
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveSettings() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.ACCOUNTING.DAILYREPORT.AUTOMATION.UPDATE, {
          activateAutomation: this.activateAutomation,
          sendAsEmail: this.sendAsEmail,
          selectedCashierIDs: this.selectedCashierIDs,
          creationTime: this.creationTime,
          endCash: {
            mode: this.mode,
            value: this.cashStock,
          },
        })
        .then(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_actionSuccessful"),
            color: "success",
          });
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
