<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('accounting.lang_nav_accounting_dailyab') "
                :icon="icon"
                :subheading="$t('accounting.lang_nav_accounting_dailyab')"
        ></page-title>
        <div class="app-main__inner">
            <daily-report-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../Layout/Components/PageTitle";
    import DailyReportComponent from "../../../components/accounting/dailyreports/DailyReportComponent";

    export default {
        components: {
            DailyReportComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-moon icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>