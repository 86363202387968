<template>
  <v-container fluid>
    <v-row>

      <v-col v-if="$store.getters['permissions/checkPermission']('dailyReportPrinting')" class="text-center mx-auto"
             cols="12" >
        <v-card :disabled="loadingPrinting || selectedReports.length<1" :loading="loadingPrinting" @click="printBulkReport"
                elevation="5">
          <div>
            <div class="text-center icons-container pa-3 mx-auto">
              <font-awesome-icon :icon="['fal' , 'print']" class="primary--text" prefix="fal" size="3x"/>
            </div>
            <v-card-title class="text-center pt-0">
              <span class="mx-auto">{{ $t('accounting.lang_Printdaily') }}</span>
            </v-card-title>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5">
        <BaseDateInput v-model="startDateTime" :disabled="this.dates_loading"
                      :label="$t('accounting.lang_dailyReportStart')"
                      :loading="this.dates_loading"
                      :rules="[v => !!v]" dense outlined
                      type="date"/>
      </v-col>
      <v-col cols="12" sm="5">
        <BaseDateInput v-model="endDateTime" :disabled="this.dates_loading"
                      :label="$t('accounting.lang_dailyReportEnd')"
                      :loading="this.dates_loading"
                      :rules="[v => !!v]" dense outlined
                      type="date"/>
      </v-col>

      <v-col class="pt-2" cols="12" sm="2">
        <v-btn block class="mx-auto elevation-0" color="primary" @click="filter()">
          <v-icon>filter_list</v-icon>
          {{ $t("generic.lang_filter") }}
        </v-btn>
      </v-col>

      <v-col cols="12">
        <Datatable
            ref="dailyarchive"
            @changeSelectedRows="selectReport"
            :api-endpoint="ENDPOINTS.DATATABLES.ACCOUNTING.DAILYREPORT"
            :data="this.params"
            :datatable-headers="this.datatableHeaders"
            :elevation="6"
            :excel-columns="excelColumns"
            excel-file-name="daily-report-archive"
            pdf-orientation="landscape"
            pdf-title="Daily report archive"
            show-display-buttons
            show-pdf
            show-select-checkbox
        >
          <template v-slot:item.total="{item}" >
            {{item.total | currency}}
          </template>
          <template v-slot:item.subtotal="{item}" >
            {{item.subtotal | currency}}
          </template>
          <template v-slot:item.tax="{item}" >
            {{item.tax | currency}}
          </template>
          <template v-slot:item.ec="{item}" >
            {{item.ec | currency}}
          </template>
          <template v-slot:item.cash="{item}" >
            {{item.cash | currency}}
          </template>
        </Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../config";
import Datatable from "../../datatable/Datatable";
import {Events} from "@/plugins/events";

import {library} from '@fortawesome/fontawesome-svg-core';
import {faPrint} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {mapGetters, mapState} from "vuex";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";
import BaseDateInput from "@/components/common/BaseDateInput.vue";
library.add(
    faPrint
);
export default {
  name: "BulkPrintDailyReportComponent",
  components: {Datatable,FontAwesomeIcon, BaseDateInput},
  data: () => {
    return {
      dates_loading: false,
      loadingPrinting: false,
      selectedReports:[],
      startDateTime: "",
      endDateTime: "",
      ENDPOINTS,
      shiftTime: "",
      startShift: "",
      endShift: ""
    }
  },
  methods: {
    printBulkReport() {
      this.loadingPrinting = true;

      this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.CUMULATEPRINTZ, {
        reportID: this.selectedReports,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {

          // EPSON EPOS PRINT
          printDataFromPrinter(this.invoicePrinter,res.data.printXML).then(()=>{
            this.selectedReports=[];
          }).catch((err)=>{
            Events.$emit("showSnackbar", {
              message: this.$t("accounting.lang_dailyClosingCouldNotBePrinted"),
              color: "error"
            });
          }).finally(()=>{
            this.loadingPrinting = false;
          })
        } else {
          this.loadingPrinting = false;

          Events.$emit("showSnackbar", {
            message: this.$t("accounting.lang_dailyClosingCouldNotBePrinted"),
            color: "error"
          });
        }

      }).catch(() => {
        this.loadingPrinting = false;

        Events.$emit("showSnackbar", {
          message: this.$t("accounting.lang_dailyClosingCouldNotBePrinted"),
          color: "error"
        });
      });
    },
    selectReport(data){
      this.selectedReports=data.map(el=>el.id);
    },
    filter() {
      //if (!this.$refs.form.validate()) return;
      this.$refs.dailyarchive.getDataFromApi();
    },
    displayEntry(entry) {
      this.$router.push('/accounting/dailyReport/details/' + entry.id);
    }
  },
  watch: {
  },
  computed: {
    ...mapState([
      'api',
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    params() {
      return {
        stockTakingID: this.$route.params.id,
        start_date: this.startDateTime === "" ? "" : new Date(this.startDateTime).getTime() / 1000,
        end_date: this.endDateTime === "" ? "" : new Date(this.endDateTime).getTime() / 1000,
      }
    },
    datatableHeaders: function () {
      return [
        {
          text: "id",
          value: "id",
          sortable: false,
          hide: true
        },
        {
          text: this.$t("accounting.lang_dailyReportID"),
          value: "reportID",
          sortable: true,
          sort: "desc"
        },
        {
          text: this.$t("generic.lang_name"),
          value: "start_name",
          sortable: true
        },
        {
          text: this.$t("accounting.lang_dailyReportStart"),
          value: "start_date",
          sortable: false
        },
        {
          text: this.$t("accounting.lang_dailyReportEnd"),
          value: "end_date"
        },
        {
          text: this.$t("generic.lang_cashierID"),
          value: "cashierID"
        },

        {
          text: this.$t("erp.lang_ware_total"),
          value: "total"
        },

        {
          text: this.$t("generic.lang_subtotal"),
          value: "subtotal"
        },

        {
          text: this.$t("erp.lang_mwst"),
          value: "tax"
        },

        {
          text: this.$t("generic.lang_ec"),
          value: "ec"
        },

        {
          text: this.$t("generic.lang_cash"),
          value: "cash"
        },
      ];
    },
    excelColumns: function () {
      return [
        {
          label: "ID",
          field: "id"
        },
        {
          label: this.$t("accounting.lang_dailyReportStart"),
          field: "start_date"
        },
        {
          label: this.$t("accounting.lang_dailyReportEnd"),
          field: "end_date"
        },
        {
          label: this.$t("accounting.lang_dailyReportID"),
          field: "reportID"
        },
        {
          label: this.$t("accounting.lang_cashierID"),
          field: "cashierID"
        },

        {
          label: this.$t("erp.lang_ware_total"),
          field: "total"
        },

        {
          label: this.$t("generic.lang_subtotal"),
          field: "subtotal"
        },

        {
          label: this.$t("erp.lang_mwst"),
          field: "tax"
        },

        {
          label: this.$t("generic.lang_ec"),
          field: "ec"
        },

        {
          label: this.$t("generic.lang_cash"),
          field: "cash"
        },
      ];
    }
  },
}
</script>

<style scoped>
.buttons {
  min-width: 200px !important;
  max-width: 300px !important;
  height: 250px !important;
  cursor: pointer !important;
}

.icons {
  font-size: 100px !important;
}
</style>